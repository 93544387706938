import { EProjectType } from 'enums/configs.enum';

export const Configs = {
  ENV: process.env.NODE_ENV,
  PROJECT: process.env.REACT_APP_PROJECT || EProjectType.LOCAL,
  API_HOST: process.env.REACT_APP_API_HOST || 'https://testapi.bigstepedu.com',
  SENTRY: process.env.REACT_APP_SENTRY,
  DEBUG: process.env.REACT_APP_PROJECT === EProjectType.LOCAL,
  REACT_APP_KAKAO_KEY: process.env.REACT_APP_KAKAO_KEY || 'cda75f3cd4112396004aca5d7da58bc8',
  EDITOR_API_KEY: process.env.EDITOR_API_KEY || 'pep089c69ke7tcpbvg0ag0qv3yyfpxgdd2bootna6c0wpki0', // TinyMCE Editor 무료버젼
  LANDING_DETAIL_VIEW: process.env.LANDING_DETAIL_VIEW || 'https://bigstepedu.com/cslab/board/view/5',
};

export default Configs;
