import { combineReducers } from 'redux';
import core from 'modules/core';
import getFrameworkData from 'modules/framework';
import createQuizReducer from 'modules/learning';
import user from 'modules/user';

const rootReducer = combineReducers({
  core,
  user,
  createQuizReducer,
  getFrameworkData,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
