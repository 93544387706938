import { FormInstance } from 'antd/es/form';

export type TQuiz = {
  id: number;
};

export enum EQuizType {
  List = 'list',
  Index = 'index',
  IssueTree = 'issue_tree',
  ValueChain = 'value_chain',
  ShortAnswer = 'short_answer',
}

export const CQuizTypeNum: { [key: string]: number } = {
  [EQuizType.List]: 1,
  [EQuizType.Index]: 2,
  [EQuizType.IssueTree]: 3,
  [EQuizType.ValueChain]: 4,
  [EQuizType.ShortAnswer]: 5,
};

export const CQuizTypeName: { [key: string]: string } = {
  [EQuizType.List]: '리스트',
  [EQuizType.Index]: '목차',
  [EQuizType.IssueTree]: '이슈트리',
  [EQuizType.ValueChain]: '벨류체인',
  [EQuizType.ShortAnswer]: '단답형',
};

export enum ESubject {
  FitInterview = 'fit_interview',
  CaseInterview = 'case_interview',
  TurnaroundStrategy = 'turnaround_strategy',
  GrowthStrategy = 'growth_strategy',
  DdMarketSizing = 'dd_market_sizing',
  OperationOptimization = 'operation_optimization',
  SalesMarketingStrategy = 'sales_marketing_strategy',
  BasicGuide = 'basic_guide',
  ResumeBasedQuestions = 'resume_based_questions',
  IBIndustryInsights = 'ib_industry_insights',
  PersonalityTraits = 'personality_traits',
  CommercialAcumen = 'commercial_acumen',
  FinalQuestions = 'final_questions',
}

export const CSubject: { [key: string]: string } = {
  [ESubject.FitInterview]: 'Fit Interview',
  [ESubject.CaseInterview]: 'Case Interview',
  [ESubject.TurnaroundStrategy]: 'Turnaround Strategy',
  [ESubject.GrowthStrategy]: 'Growth Strategy',
  [ESubject.DdMarketSizing]: 'DD & Market Sizing',
  [ESubject.OperationOptimization]: 'Operation Optimization',
  [ESubject.SalesMarketingStrategy]: 'Sales & Marketing Strategy',
  [ESubject.BasicGuide]: 'Basic Guide',
  [ESubject.ResumeBasedQuestions]: 'Resume_based Questions',
  [ESubject.IBIndustryInsights]: 'IB Industry Insights',
  [ESubject.PersonalityTraits]: 'Personality Traits',
  [ESubject.CommercialAcumen]: 'Commercial Acumen',
  [ESubject.FinalQuestions]: 'Final Questions',
};

export enum EQuizStep {
  Clarification = 'clarification',
  Structuring = 'structuring',
  Analysis = 'analysis',
  SolutionWrapUp = 'solution_wrap_up',
}

// export enum EQuizStepIndex {
//   Clarification = 0,
//   Structuring = 1,
//   Analysis = 2,
//   SolutionWrapUp = 3
// }

export const CQuizStepIndex: { [key: string]: number } = {
  [EQuizStep.Clarification]: 0,
  [EQuizStep.Structuring]: 1,
  [EQuizStep.Analysis]: 2,
  [EQuizStep.SolutionWrapUp]: 3,
};

export const CQuizStep: { [key: string]: string } = {
  [EQuizStep.Clarification]: 'Clarification',
  [EQuizStep.Structuring]: 'Structuring',
  [EQuizStep.Analysis]: 'Analysis',
  [EQuizStep.SolutionWrapUp]: 'Solution & Wrap-up',
};

//문제 정보 get
export type TSelectOption = {
  id: number;
  option_num: number;
  option: string;
};

export type TUserViewDescription = {
  description: string;
  commentary: string;
};

export type TQuizConnectedExamples = {
  id: number | null;
  subject: string | null;
  title: string;
};

export type TSteps = {
  step_name: string;
  step_index: number;
};

export type THeaderQuizInfo = {
  quiz_number: number;
  quiz_title: string;
  quiz_step: TSteps;
  quiz_subject: string;
  quiz_group_id: number | null;
  connected_example_id: number | null;
};

//문제 post, get 전체 state
//리덕스 관리 state type 정의
export type TCreateQuizState = {
  [key: string]: any;
  quiz_number: number;
  title: string;
  subject: string;
  is_sample: boolean;
  quiz_group_id: number | null;
  example_id: number | null;
  quiz_type: string; //단계, step
  quiz_body: Array<TQuizbody>;
  quiz_option: Array<TQuizOption>;
  quiz_answer: Array<TQuizAnswer>;
  answer_type: string;
  is_completed: boolean;
  //초기 생성에는 없는 값
  id?: number; //post에는 없음 put에는 있음
  options?: Array<TQuizOption>; // 옵션 && 답안지 모두 포함
  quiz_group?: { id: number | null; name: string | null }; //post랑 다름
  example?: { id: number | null; subject: string | null; title: string }; // post랑 다름
  previous_id?: number;
  next_id?: number;
  is_previous_solved?: boolean;
  is_next_solved?: boolean;
  full_drill?: { [key: string]: boolean | number };
};

export type TQuizbody = {
  id?: number;
  body: string;
  is_explanation: boolean;
};

//문제 생성
// export type TAddedOption = {
//   content_number: number;
//   content: string;
// };

export type TQuizOption = {
  id?: number | null;
  content_number: number;
  content: string;
};

export type TAddedListAnswer = {
  is_shown: boolean;
  content_number: number;
  content: string;
  id?: number | null;
};

export type TQuizAnswer = {
  id?: number | null;
  content_number: number;
  content: string;
  parent: number | null;
  column_index: number | null;
  depth: number | null;
  is_shown: boolean;
  is_answer?: boolean;
};
export type TGradedAnswer = {
  id?: number | null;
  content_number: number;
  content: string;
  is_answer?: boolean;
};

export type TQuizIndexAnswer = {
  id?: number | null;
  content_number: number;
  content: string;
  parent: number | null;
  column_index: number;
  depth: number;
  is_shown: boolean;
};

//get Data
export type TGetQuizState = {
  [key: string]: any;
  quiz_number: number; //
  title: string; //
  subject: string; //
  is_sample: boolean; //
  // quiz_group_id: number | null;
  // example_id: number | null;
  quiz_type: string; //
  quiz_body: Array<TQuizbody>; // 여기에 id 값 포함
  quiz_option: Array<TQuizOption>; // 여기에 id 값 포함 // 옵션만
  quiz_answer: Array<TQuizAnswer>; // 여기에 id 값 포함 // 답안지 정보
  answer_type: string; //
  is_completed: boolean; //

  options: Array<TQuizOption>; // 옵션 && 답안지 모두 포함
  quiz_group: { id: number | null; name: string | null }; //post랑 다름
  example: { id: number | null; subject: string | null; title: string }; // post랑 다름
  id: number; //post에는 없음
};

//index type answer
export type TItemData = {
  column_index: number;
  depth: number;
  content: string | undefined;
  is_shown: boolean;
  content_number: number | undefined;

  id?: number | null;
  parent?: number | null;
};

export type TColumnData = {
  column_index: number;
  depth: number;
  content: string | undefined;
  is_shown: boolean;
  content_number: number | undefined;
  items: Array<TItemData>;

  id?: number | null;
  parent?: number | null;
};

export type TIndexAnswer = {
  columns: Array<TColumnData>;
};

//답안지 부분 input wrapper 컴포넌트
export type TInputWrapperProps = {
  col: number;
  depth: number;
  indexAnswerState: TIndexAnswer;
  setIndexAnswerState: (x: any) => void;
  form: FormInstance;
  isShown?: boolean;
  options?: Array<TQuizOption>;
  setOptions?: (x: any) => void;
  userAnswer?: Array<TQuizOption | TQuizAnswer>;
  setUserAnswer?: (x: any) => void;
  defaultAnswer?: Array<TQuizAnswer>;
  setDefaultAnswer?: (x: any) => void;
  answer_id?: number | null;
};

//의견 보내기
export type TCustomerOpinion = {
  body: string;
  quiz_id: number;
};

//도움말 현재 페이지
export type TCurrentProcess = {
  step: number;
  name: string;
};

export type TCurrentProcessProps = {
  currentProcess?: boolean;
};

//도움말 popper

export enum EQuizProcessName {
  Question = 'question',
  Option = 'option',
  Answer = 'answer',
  GradingButton = 'grading_button',
  Commentary = 'commentary',
  QuizStep = 'quiz_step',
}

export type TQuizProcess = {
  step: number;
  name: string;
  title: string;
  description: string;
};

export const CQuizProcess: Array<TQuizProcess> = [
  {
    step: 1,
    name: EQuizProcessName.Question,
    title: '문제',
    description: '문제 내용과 참고 사항을 읽어보세요.',
  },
  {
    step: 2,
    name: EQuizProcessName.Option,
    title: '선택지',
    description: '답안의 후보들이 나열되어 있는 선택지 영역입니다.',
  },
  {
    step: 3,
    name: EQuizProcessName.Answer,
    title: '답안지',
    description: '이곳에서 답안을 선택하세요.',
  },
  {
    step: 4,
    name: EQuizProcessName.GradingButton,
    title: '채점하기',
    description: '답안 작성을 완료한 뒤 ‘채점하기’를 누르면 채점이 시작됩니다.',
  },
  {
    step: 5,
    name: EQuizProcessName.Commentary,
    title: '해설',
    description: '채점 결과와 해설을 확인하세요.',
  },
  {
    step: 6,
    name: EQuizProcessName.QuizStep,
    title: '단계 선택',
    description: '연관된 문제 그룹의 다른 단계로 이동하며 문제를 풀어 보세요.',
  },
];
