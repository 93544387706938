export type TBoardTypes = {
  id: number;
};

export type TMAIN_THEMES = {
  id: number;
  name: string;
  subtitle: string;
  link: string;
  icon: string;
  color: string;
};

export type TSubjectLayout = {
  pageTitle?: string;
  pageSubtitle?: string;
  children?: any;
};

export type TDetailLayout = {
  img?: string;
  hasSubject?: boolean;
  subject?: string;
};

export type TSubTheme = {
  id: number;
  thumbnail: any;
  subject: string;
  title: string;
  subtitle: string;
  exercise_button: string;
  body_list?: TBodyList[];
  example: any;
  quiz_id: number | null;
};

export type TSubcategory = {
  id: number;
  subject: string;
  name: string;
};

export type TBodyList = {
  body: string;
};

export type TLearningParams = {
  category: string;
  step_id: string;
  example_id: string;
  operation: string;
};

export type TRecommendCardBox = {
  list: any;
  title: string;
};

export type TModalInfo = {
  subject: string;
  subtitle: string;
  exercise_button: string;
  is_example: boolean;
  subcategory?: string | null;
  mentoring_field: string;
};

export type TLearningBoard = {
  pathName: any;
  operation: any;
  isEdit?: boolean;
  record?: any;
  isExample?: boolean;
};

export const CSNewsFarameworksIntroduction = '/cslab/board/view/78';
