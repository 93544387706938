// 액션 타입 선언
type TinitialState = {
  board_number: number | null;
  subject: string;
  subtitle: string;
  is_example: boolean;
  exercise_button: string;
  subcategory?: string | null;
  mentoring_field: string;
};

const GET_BASIC_DATA = 'case/framework/BASIC_DATA' as const;
const RESET_STATE = 'case/framework/RESET_STATE' as const;

export const getBasicData = (data: any) => {
  return {
    type: GET_BASIC_DATA,
    payload: data,
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE,
    payload: initialState,
  };
};

const initialState: TinitialState = {
  board_number: null,
  subject: '',
  subtitle: '',
  is_example: false,
  exercise_button: 'top',
  subcategory: null,
  mentoring_field: '',
};

const getFrameworkData = (state: TinitialState = initialState, action: any): any => {
  switch (action.type) {
    case GET_BASIC_DATA:
      return {
        board_number: action.payload.board_number,
        subject: action.payload.subject,
        subtitle: action.payload.subtitle,
        is_example: action.payload.is_example,
        exercise_button: action.payload.exercise_button,
        subcategory: action.payload.subcategory,
        mentoring_field: action.payload.mentoring_field,
      };
    case RESET_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};

export default getFrameworkData;
