import produce from 'immer';
import { THeaderQuizInfo, TQuizOption, TCreateQuizState, TQuizAnswer, TQuizbody } from 'modules/learning';

//액션 타입 선언
const GET_QUIZ_DATA = 'createQuiz/FETCH_QUIZ_DATA' as const;
const ADD_HEADER_INFO = 'createQuiz/ADD_BASIC_INFO' as const;
const ADD_DESCRIPTION = 'createQuiz/ADD_DESCRIPTION' as const;
const ADD_OPTION = 'createQuiz/ADD_OPTION' as const;
const ADD_ANSWER = 'createQuiz/ADD_ANSWER' as const;
const RESET_STATE = 'createQuiz/RESET_STATE' as const;

//모든 액션 생성 함수
type TCreateQuizActions =
  | ReturnType<typeof getQuizData>
  | ReturnType<typeof addHeaderInfo>
  | ReturnType<typeof addDescription>
  | ReturnType<typeof addOption>
  | ReturnType<typeof addAnswer>
  | ReturnType<typeof resetState>;

//액션 생성 함수 선언
//useParams()에 id값있으면 해당 문제 정보 get한 데이터에서
export const getQuizData = (data: any) => {
  return {
    type: GET_QUIZ_DATA,
    payload: data,
  };
};

//모달에서 설정한 값들
export const addHeaderInfo = (headerInfo: THeaderQuizInfo) => {
  return {
    type: ADD_HEADER_INFO,
    payload: headerInfo,
  };
};

//문제/해설
//value = {step:any, description:string, commentary:string}
export const addDescription = (value: { description: string; commentary: string }) => {
  return {
    type: ADD_DESCRIPTION,
    payload: value,
  };
};
//선택 옵션 관리
export const addOption = (value: Array<TQuizOption>) => {
  return {
    type: ADD_OPTION,
    payload: value,
  };
};

//답안지 관리
export const addAnswer = (answerType: string, answerList: Array<TQuizAnswer>) => {
  return {
    type: ADD_ANSWER,
    payload: { answerType: answerType, answerList: answerList },
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

//initialState
const initialState: TCreateQuizState = {
  quiz_number: 0,
  title: '',
  subject: '',
  quiz_group_id: null,
  example_id: null,
  quiz_type: '',
  quiz_body: [],
  quiz_option: [],
  quiz_answer: [],
  answer_type: '',
  is_completed: false,
  is_sample: false,
};

//리듀서
const createQuizReducer = (
  state: TCreateQuizState = initialState,
  action: TCreateQuizActions,
): TCreateQuizState => {
  switch (action.type) {
    case GET_QUIZ_DATA:
      return {
        ...state,
        // ...action.payload,
        quiz_number: action.payload.quiz_number,
        title: action.payload.title,
        subject: action.payload.subject,
        quiz_group_id: action.payload.quiz_group.id, //quiz_group에서 id값만
        example_id: action.payload.example.id, //example에서 id 값만
        quiz_type: action.payload.quiz_type,
        quiz_body: action.payload.quiz_body,
        quiz_option: action.payload.quiz_option,
        quiz_answer: action.payload.quiz_answer,
        answer_type: action.payload.answer_type,
        is_completed: action.payload.is_completed,
        is_sample: action.payload.is_sample,
        //초기 생성에는 없는 값(수정 할 때 기존 데이터 받기 때문에 있는 값)
        id: action.payload.id,
        options: action.payload.options, // 어드민 등록 및 수정에서는 필요 없지만 사용자 뷰 데이터에서 필요함
        quiz_group: action.payload.quiz_group,
        example: action.payload.example,
        previous_id: action.payload.previous_id,
        next_id: action.payload.next_id,
        is_previous_solved: action.payload.is_previous_solved,
        is_next_solved: action.payload.is_next_solved,
        full_drill: action.payload.full_drill,
      };

    case ADD_HEADER_INFO:
      const {
        quiz_number,
        quiz_title,
        quiz_subject,
        quiz_group_id,
        connected_example_id,
        quiz_step,
      } = action.payload;
      return {
        ...state,
        quiz_number: quiz_number,
        title: quiz_title,
        subject: quiz_subject,
        is_sample: false, //수정 필요
        quiz_group_id: quiz_group_id,
        example_id: connected_example_id,
        quiz_type: quiz_step.step_name, // 단계 이름
      };

    case ADD_DESCRIPTION:
      const { description, commentary } = action.payload;

      const nextState = produce(state, (draft) => {
        const descriptionElement = draft.quiz_body.find((el: TQuizbody) => el.is_explanation === false);
        if (descriptionElement) {
          descriptionElement.body = description;
        }
        const commentaryElement = draft.quiz_body.find((el: TQuizbody) => el.is_explanation === true);
        if (commentaryElement) {
          commentaryElement.body = commentary;
        }
      });
      // id값 있을 땐는 id 값 유지하면서 상태 변경하도록
      if (state.quiz_body.length >= 1) {
        //아얘 초기 작성은 아니라는 것 (임시 저장했거나, 등록하고 수정하러 들어왔다는것)
        return {
          ...nextState,
        };
      } else {
        return {
          ...state,
          quiz_body: [
            { is_explanation: true, body: commentary },
            { is_explanation: false, body: description },
          ],
        };
      }

    case ADD_OPTION:
      if (state.id) {
        //리덕스 state에 id 값이 있다면 수정데이터라는 것
        const addNullId = action.payload.map((el: TQuizOption) => (el.id ? el : { ...el, id: null }));

        return { ...state, quiz_option: addNullId };
      } else {
        return { ...state, quiz_option: action.payload };
      }

    case ADD_ANSWER:
      if (state.id) {
        //리덕스 stat에 id 값이 있다면 수정데이터라는 것
        const addNullId = action.payload.answerList.map((el: TQuizAnswer) =>
          el.id ? el : { ...el, id: null },
        );

        return {
          ...state,
          answer_type: action.payload.answerType,
          quiz_answer: addNullId,
        };
      } else {
        return {
          ...state,
          answer_type: action.payload.answerType,
          quiz_answer: action.payload.answerList,
        };
      }

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default createQuizReducer;
